import {Component, forwardRef, input} from '@angular/core';
import {SliderChangeEvent, SliderModule} from "primeng/slider";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgStyle} from "@angular/common";
import {PrimeTemplate} from "primeng/api";

@Component({
  selector: 'app-custom-slider',
  standalone: true,
  imports: [
    SliderModule,
    FormsModule,
    NgStyle,
    PrimeTemplate
  ],
  templateUrl: './custom-slider.component.html',
  styleUrl: './custom-slider.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSliderComponent),
    multi: true
  }]
})
export class CustomSliderComponent implements ControlValueAccessor {
  label = input.required<string>();


  value: number = 0;
  ticks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  disabled: boolean = false;

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateValue(newValue: SliderChangeEvent) {
    if (newValue.value !== undefined) {
      this.value = newValue.value;
      this.onChange(this.value);
      this.onTouched();
    }
  }

  private onChange: (value: number) => void = () => {
  };

  private onTouched: () => void = () => {
  };
}
