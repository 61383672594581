import {Component} from '@angular/core';
import {NgStyle} from "@angular/common";
import {setupTimeWidth, speedWidth, taskWidth, workCenterWidth} from "../column-widths";

@Component({
  selector: 'app-htt-header-row',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './htt-header-row.component.html',
  styleUrl: './htt-header-row.component.scss'
})
export class HttHeaderRowComponent {

  protected readonly workCenterWidth = workCenterWidth;
  protected readonly taskWidth = taskWidth;
  protected readonly speedWidth = speedWidth;
  protected readonly setupTimeWidth = setupTimeWidth;
}
