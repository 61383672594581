import {Component, inject, OnInit} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {ProcessParameter} from "../../api/auto-gen";
import {TableModule} from "primeng/table";
import {CurrencyPipe} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {Button} from "primeng/button";

@Component({
  selector: 'app-adjust-parameter-dialog',
  standalone: true,
  imports: [
    TableModule,
    CurrencyPipe,
    DropdownModule,
    Button
  ],
  templateUrl: './adjust-parameter-dialog.component.html',
  styleUrl: './adjust-parameter-dialog.component.scss'
})
export class AdjustParameterDialogComponent implements OnInit {
  parameters: ProcessParameter[] = [
    {
      constant_uuid: '',
      name: 'Länge Netz mm',
      value: 1,
      adjusted_value: null,
    },
    {
      constant_uuid: '',
      name: 'Abstand Rand mm',
      value: 1,
      adjusted_value: null,
    },
    {
      constant_uuid: '',
      name: 'Abstand Rand mm',
      value: 1,
      adjusted_value: null,
    },
    {
      constant_uuid: '',
      name: 'Abstand Rand mm',
      value: 1,
      adjusted_value: null,
    },
    {
      constant_uuid: '',
      name: 'Abstand Rand mm',
      value: 1,
      adjusted_value: null,
    },
    {
      constant_uuid: '',
      name: 'Bandgeschwindigkeit',
      value: 20,
      adjusted_value: 25,
    },
    {
      constant_uuid: '',
      name: 'Abstand Rand mm',
      value: 1,
      adjusted_value: null,
    },
  ];
  // private dynamicDialogConfig = inject(DynamicDialogConfig);
  private ref = inject(DynamicDialogRef);

  ngOnInit() {
    //TODO: handle correct dialog input
  }

  cancel() {
    this.ref.close();
  }

  save() {
    //TODO: handle save for adjust parameters
    this.ref.close(true);
  }
}
