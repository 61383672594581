<div class="option-upsert-div flex flex-column gap-7 h-full">
  <p class="screen-title text-color font-medium">
    @if (optionUpsertService.projectOptionUuid()) {
      Update Option {{ optionUpsertService.projectOption()?.name }}
    } @else {
      Create new option
    }

  </p>
  <div class="upsert-steps flex flex-column h-full gap-3">
    <p-tabMenu (activeItemChange)="onActiveItemChange($event)" [activeItem]="active" [model]="items"/>
    <div class="flex-grow-1">
      <ng-container [formGroup]="optionForm" class="h-full">
        @if (items.indexOf(active) == 0) {
          <app-space-setup-component formGroupName="spaceSetup"/>
        } @else if (optionUpsertService.projectOption() && optionUpsertService.projectOptionUuid()) {
          @if (items.indexOf(active) == 1) {
            <app-reference-file-component/>
          } @else if (items.indexOf(active) == 2) {
            <app-forming-component formGroupName="forming"
                                   [projectOptionUuid]="optionUpsertService.projectOptionUuid()!"/>
          } @else if (items.indexOf(active) == 3) {
            <app-sizing-component formGroupName="sizing"/>
          } @else if (items.indexOf(active) == 4) {
            <app-htt-component/>
          } @else if (items.indexOf(active) == 5) {
            <app-other-processes-component/>
          }
        }
      </ng-container>
    </div>
    <div class="flex gap-3 w-full justify-content-end align-items-center">
      @if (items.indexOf(active) > 0 && this.optionUpsertService.allowEditing()) {
        <p-button (onClick)="previousStep()" severity="secondary">Previous Step</p-button>
      }
      @if (items.indexOf(active) < items.length - 1) {
        <p-button (onClick)="nextStep()">Next Step</p-button>
      } @else {
        <p-button>Save</p-button>
      }
    </div>
  </div>
</div>
