import {Component, inject, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {MenuModule} from "primeng/menu";
import {NgOptimizedImage} from "@angular/common";
import {TabMenuModule} from "primeng/tabmenu";
import {NavigationEnd, Router} from "@angular/router";
import {InfoDialogComponent} from "./info-dialog/info-dialog.component";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-app-bar',
  standalone: true,
  templateUrl: './app-bar.component.html',
  imports: [
    MenuModule,
    NgOptimizedImage,
    TabMenuModule
  ],
  styleUrls: ['./app-bar.component.scss'],
  providers: [DialogService],
})
export class AppBarComponent implements OnInit {
  items: MenuItem[] = [];
  menuItems: MenuItem[] = [];

  activeItem: MenuItem | undefined;

  dialogService = inject(DialogService);

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.items = [
      {
        label: 'About',
        icon: 'pi pi-info-circle',
        command: () => {
          this.about();
        },
      },
    ];
    this.menuItems = [
      {
        label: 'User Panel',
        route: '/projects',
      },
      {
        label: 'Admin Panel',
        route: '/admin',
      },
    ]
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActiveItem(event.urlAfterRedirects);
      }
    });
  }

  setActiveItem(currentRoute: string) {
    this.activeItem = this.menuItems.find(item => item['route'].startsWith(currentRoute)) || this.menuItems[0];
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }


  about() {
    this.dialogService.open(
      InfoDialogComponent,
      {
        header: "Infos",
        styleClass: 'info-dialog',
        closeOnEscape: true,
        dismissableMask: true,
        showHeader: true,
      },
    );
  }

}
