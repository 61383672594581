<p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="layers()">
  <ng-template pTemplate="header">
    <tr>
      <th class="text-color">Sublayer</th>
      <th class="text-color">Type</th>
      <th class="text-color">z</th>
      <th class="text-color">delta</th>
      <th class="text-color">APress</th>
      <th class="text-color">FPress</th>
      <th class="text-color">lenCrit</th>
    </tr>
  </ng-template>
  <ng-template let-layer pTemplate="body">
    <tr>
      <td class="text-color">{{ layer.sublayer }}</td>
      <td class="text-color">{{ layer.type }}</td>
      <td class="text-color">{{ layer.z }} mm</td>
      <td class="text-color">{{ layer.delta ?? '-' }}</td>
      <td class="text-color">{{ layer.apress }} mm2</td>
      <td class="text-color">{{ layer.fpress }} kN</td>
      <td class="text-color">{{ layer.len_crit }} mm</td>
    </tr>
  </ng-template>
</p-table>
