import {Component, input, output} from '@angular/core';
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-project-state-filter-buttons-component',
  standalone: true,
  imports: [
    Button,
    Ripple,
    NgClass
  ],
  templateUrl: './project-state-filter-buttons-component.component.html',
  styleUrl: './project-state-filter-buttons-component.component.scss'
})
export class ProjectStateFilterButtonsComponentComponent {
  selected = input<'open' | 'won' | 'lost' | null | undefined>();
  onChanged = output<'open' | 'won' | 'lost' | null | undefined>();
}
