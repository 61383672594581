<div class="flex gap-3">
  <p-button (onClick)="onChanged.emit('open')" [ngClass]="{'selected-button': selected() === 'open'}"
            class="project-state-button"
            label="Open" pRipple/>
  <p-button (onClick)="onChanged.emit('won')" [ngClass]="{'selected-button': selected() === 'won'}"
            class="project-state-button"
            label="Won" pRipple/>
  <p-button (onClick)="onChanged.emit('lost')" [ngClass]="{'selected-button': selected() === 'lost'}"
            class="project-state-button"
            label="Lost" pRipple/>
</div>
