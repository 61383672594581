import {Component, inject, input, OnInit} from '@angular/core';
import {SimilarItem} from "../../api/auto-gen";
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {ProgressBarModule} from "primeng/progressbar";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";
import {DividerModule} from "primeng/divider";
import {FormingUpsertComponentComponent} from "./forming-upsert-component/forming-upsert-component.component";
import {Button} from "primeng/button";
import {CurrencyPipe} from "@angular/common";
import {OptionUpsertService} from "../option-upsert-service.service";

@Component({
  selector: 'app-forming-component',
  standalone: true,
  imports: [
    PrimeTemplate,
    TableModule,
    ProgressBarModule,
    ReactiveFormsModule,
    InputNumberModule,
    DividerModule,
    FormingUpsertComponentComponent,
    Button,
    CurrencyPipe
  ],
  templateUrl: './forming-component.component.html',
  styleUrl: './forming-component.component.scss',
})
export class FormingComponentComponent implements OnInit {
  formGroupName = input<string>('');
  projectOptionUuid = input<string>('');
  form: FormGroup | undefined;
  mainProcess: 'FOR' = 'FOR';
  protected optionUpsertService = inject(OptionUpsertService)
  private rootFormGroup = inject(FormGroupDirective);

  ngOnInit() {
    this.optionUpsertService.fetchSimilarItems(this.mainProcess);
    this.form = this.rootFormGroup.control.get(this.formGroupName()) as FormGroup;
  }

  applySimilarItem(item: SimilarItem) {
    if (this.form) {
      this.form.setValue({
        mainprocess: this.form.value.mainprocess,
        order: this.form.value.order,
        workcenter: item.workcenter_uuid,
        task: this.form.value.task,
        speed: item.speed,
        setup_time: item.setup_time,
        hourly_rate: item.hourly_rate,
        parameters: this.form.value.parameters,
      })
    }
  }
}
