import {Routes} from '@angular/router';
import {AdminPanelComponent} from "./admin-panel/admin-panel.component";
import {ProjectOverviewPageComponent} from "./project-overview-page/project-overview-page.component";
import {OptionUpsertPageComponent} from "./option-upsert-page/option-upsert-page.component";
import {UserPanelComponent} from "./user-panel/user-panel.component";
import {ProjectPageComponent} from "./user-panel/project-page/project-page.component";
import {OptionsPageComponent} from "./project-overview-page/options-page/options-page.component";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: 'projects',
    component: UserPanelComponent,
    children: [
      {
        path: '',
        component: ProjectPageComponent,
      },
      {
        path: ':id/options',
        component: ProjectOverviewPageComponent,
        children: [
          {
            path: '',
            component: OptionsPageComponent,
          },
          {
            path: 'new',
            component: OptionUpsertPageComponent,
          },
          {
            path: ':optionId',
            component: OptionUpsertPageComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'admin',
    component: AdminPanelComponent,
  },
];
