import {Component, OnInit} from '@angular/core';
import packageJson from "../../../../../package.json"
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-info-dialog',
  standalone: true,
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  appVersion: string;
  angularVersion: string;
  primeNgVersion: string;
  environment: string;

  constructor() {
    this.appVersion = environment.version;
    this.angularVersion = packageJson.dependencies["@angular/core"];
    this.primeNgVersion = packageJson.dependencies.primeng;
    this.environment = environment.production ? 'Prod' : 'Dev'
  }

  ngOnInit() {
  }
}
