import {Component, input, output} from '@angular/core';
import {ProjectOption} from "../../../api/auto-gen";
import {
  ProjectOptionCardComponentComponent
} from "./project-option-card-component/project-option-card-component.component";

@Component({
  selector: 'app-project-option-list-component',
  standalone: true,
  imports: [
    ProjectOptionCardComponentComponent
  ],
  templateUrl: './project-option-list-component.component.html',
  styleUrl: './project-option-list-component.component.scss'
})
export class ProjectOptionListComponentComponent {
  projectOptions = input.required<ProjectOption[]>();
  onDuplicateOption = output<string>();
  onArchiveOption = output<string>();
}
