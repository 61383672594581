import {computed, inject, signal} from '@angular/core';
import {
  CostingProcessService,
  Layer,
  ProcessCreate,
  ProcessInput,
  ProcessOutput,
  Project,
  ProjectOption,
  ProjectOptionCreate,
  ProjectOverviewService,
  ReferenceFileSettings,
  ResponseModelLayerList,
  ResponseModelProcess,
  ResponseModelProcessList,
  ResponseModelProject,
  ResponseModelProjectOption,
  ResponseModelReferenceFileProperties,
  ResponseModelSimilarItemList,
  SimilarItem
} from "../api/auto-gen";
import {ToastService} from "../shared/services/toast-service.service";
import {Router} from "@angular/router";
import {FileUploadHandlerEvent} from "primeng/fileupload";
import {FormControl} from "@angular/forms";

export class OptionUpsertService {
  projectUuid = signal<string>('');
  project = signal<Project | undefined>(undefined);
  projectOptionUuid = signal<string | undefined>(undefined);
  projectOption = signal<ProjectOption | undefined>(undefined);
  similarItems = signal<SimilarItem[]>([]);
  projectLayers = signal<Layer[]>([])
  forProcess = signal<ProcessOutput | undefined>(undefined);
  sizProcess = signal<ProcessOutput | undefined>(undefined);

  allowEditing = computed(() => {
    return this.project()?.status == 'open' && this.projectOption()?.status == 'open';
  })

  skipSizing = new FormControl<boolean>(false);
  private costingProcessService = inject(CostingProcessService);
  private projectOverviewService = inject(ProjectOverviewService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  initializeUpsertService(projectUuid: string, projectOptionUuid: string | undefined) {
    this.projectUuid.set(projectUuid);
    this.fetchProject();
    this.projectOptionUuid.set(projectOptionUuid);
    if (this.projectOptionUuid()) {
      this.fetchProjectOption();
    }
  }

  fetchProject() {
    this.projectOverviewService.getProjectProjectProjectsProjectUuidGet({
      projectUuid: this.projectUuid(),
    })
      .pipe()
      .subscribe({
          next: (response: ResponseModelProject) => {
            if (response.data) {
              this.project.set(response.data!);
            }

          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }


  fetchProjectOption() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProjectOptionCostingProjectOptionsProjectOptionUuidGet(
        {projectOptionUuid: this.projectOptionUuid()!},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOption) => {
              if (response.data) {
                this.projectOption.set(response.data);
                this.skipSizing.setValue(response.data.skip_sizing ?? false);
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  addProjectOption(projectOptionCreate: ProjectOptionCreate) {
    if (this.projectUuid()) {
      this.costingProcessService.createProjectOptionCostingProjectsProjectUuidProjectOptionsPost(
        {projectUuid: this.projectUuid()!, projectOptionCreate: projectOptionCreate},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOption) => {
              if (response.data) {
                this.projectOption.set(response.data);
                this.projectOptionUuid.set(response.data.project_option_uuid);
                this.router.navigate(['projects', this.projectUuid(), 'options', this.projectOptionUuid()], {queryParams: {step: 'referenceFile'}});
              }
              this.toastService.pushSuccessToast('ProjectOption created successfully.');
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  updateProjectOption(newName?: string, skipSizing?: boolean) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch(
        {projectOptionUuid: this.projectOptionUuid()!, newName: newName, skipSizing: skipSizing},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOption) => {
              if (response.data) {
                this.fetchProjectOption();
              }
              this.toastService.pushSuccessToast('ProjectOption updated successfully.');
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  uploadFile(event: FileUploadHandlerEvent) {
    if (this.projectOptionUuid()) {
      const formData: FormData = new FormData();
      for (let file of event.files) {
        formData.append('file', file, file.name);
        //TODO: handle file conversion
      }
      this.costingProcessService.uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost({
        projectOptionUuid: this.projectOptionUuid()!,
        file: event.files[0]
      })
        .pipe()
        .subscribe({
            next: (_: ResponseModelReferenceFileProperties) => {
              this.fetchProjectOption();
              this.toastService.pushSuccessToast('File uploaded successfully!');
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  detectLayers(settings: ReferenceFileSettings) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost({
        projectOptionUuid: this.projectOptionUuid()!,
        referenceFileSettings: settings,
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelLayerList) => {
              if (response.data) {
                this.projectLayers.set(response.data.layers);
                this.fetchProjectOption();
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchFORProcess() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(
        {projectOptionUuid: this.projectOptionUuid()!, mainprocess: 'FOR'},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessList) => {
              if (response.data && response.data!.processes.length == 1) {
                this.forProcess.set(response.data.processes[0]);
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchSIZProcess() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(
        {projectOptionUuid: this.projectOptionUuid()!, mainprocess: 'SIZ'},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessList) => {
              if (response.data && response.data!.processes.length == 1) {
                this.sizProcess.set(response.data.processes[0]);
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchSimilarItems(mainProcess: "FOR" | "SIZ") {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet(
        {projectOptionUuid: this.projectOptionUuid()!, mainprocess: mainProcess},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelSimilarItemList) => {
              this.similarItems.set(response.data?.similar_items ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  addProcess(processCreate: ProcessCreate) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.createProcessCostingProjectOptionsProjectOptionUuidProcessPost(
        {projectOptionUuid: this.projectOptionUuid()!, processCreate: processCreate},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcess) => {
              if (response) {
                this.toastService.pushSuccessToast('Process added successfully!');
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  updateProcess(process: ProcessInput, processCreate: ProcessCreate) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut(
        {
          projectOptionUuid: this.projectOptionUuid()!,
          processUuid: process.process_uuid,
          processInput: {process_uuid: process.process_uuid, cost_per_piece: process.cost_per_piece, ...processCreate}
        },
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcess) => {
              if (response) {
                this.toastService.pushSuccessToast('Process updated successfully!');
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  updateFORProcess(processCreate: ProcessCreate) {
    if (this.forProcess()) {
      this.updateProcess(this.forProcess()!, processCreate);
    }
  }

  updateSIZProcess(processCreate: ProcessCreate) {
    if (this.sizProcess()) {
      this.updateProcess(this.sizProcess()!, processCreate);
    }
  }
}
