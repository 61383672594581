import {Component, effect, inject, OnInit, viewChild} from '@angular/core';
import {Button} from "primeng/button";
import {MenuItem} from "primeng/api";
import {StepsModule} from "primeng/steps";
import {SpaceSetupComponentComponent} from "./space-setup-component/space-setup-component.component";
import {ReferenceFileComponentComponent} from "./reference-file-component/reference-file-component.component";
import {TabMenuModule} from "primeng/tabmenu";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormingComponentComponent} from "./forming-component/forming-component.component";
import {SizingComponentComponent} from "./sizing-component/sizing-component.component";
import {HttComponentComponent} from "./htt-component/htt-component.component";
import {OtherProcessesComponentComponent} from "./other-processes-component/other-processes-component.component";
import {ActivatedRoute, Router} from "@angular/router";
import {OptionUpsertService} from "./option-upsert-service.service";

@Component({
  selector: 'app-option-upsert-page-component',
  standalone: true,
  imports: [
    Button,
    StepsModule,
    SpaceSetupComponentComponent,
    ReferenceFileComponentComponent,
    TabMenuModule,
    ReactiveFormsModule,
    FormingComponentComponent,
    SizingComponentComponent,
    HttComponentComponent,
    OtherProcessesComponentComponent
  ],
  templateUrl: './option-upsert-page.component.html',
  styleUrl: './option-upsert-page.component.scss',
  providers: [OptionUpsertService],
})
export class OptionUpsertPageComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  optionUpsertService = inject(OptionUpsertService);
  items: MenuItem[] = [];
  active: MenuItem = this.items[0];
  httTab = viewChild(HttComponentComponent);
  private fb = inject(FormBuilder);
  optionForm: FormGroup = this.fb.group({
    spaceSetup: this.fb.group({
      name: ['', Validators.required],
      site: ['', Validators.required],
      lot_quantity: [undefined, Validators.required],
      yearly_quantity: [undefined, Validators.required],
    }),
    forming: this.fb.group(
      {
        mainprocess: ['FOR', Validators.required],
        order: [0, Validators.required],
        workcenter: ['', Validators.required],
        task: [null, Validators.required],
        speed: [null, Validators.required],
        setup_time: [null, Validators.required],
        hourly_rate: [null, Validators.required],
        parameters: [[]],
      }),
    sizing: this.fb.group({
      mainprocess: ['SIZ', Validators.required],
      order: [0, Validators.required],
      workcenter: ['', Validators.required],
      task: [null, Validators.required],
      speed: [null, Validators.required],
      setup_time: [null, Validators.required],
      hourly_rate: [null, Validators.required],
      parameters: [[]],
    })
  });

  constructor() {
    effect(() => {
      if (this.optionUpsertService.projectOption()) {
        const spaceSetupGroup = this.optionForm.get('spaceSetup') as FormGroup;
        spaceSetupGroup.setValue({
          name: this.optionUpsertService.projectOption()?.name ?? '',
          site: this.optionUpsertService.projectOption()?.site ?? '',
          lot_quantity: this.optionUpsertService.projectOption()?.lot_quantity ?? undefined,
          yearly_quantity: this.optionUpsertService.projectOption()?.yearly_quantity ?? undefined,
        })
        spaceSetupGroup.get('site')?.disable();
        spaceSetupGroup.get('lot_quantity')?.disable();
        spaceSetupGroup.get('yearly_quantity')?.disable();
      }
      let forProcess = this.optionUpsertService.forProcess();
      if (forProcess) {
        const formingGroup = this.optionForm.get('forming') as FormGroup;
        formingGroup?.setValue({
          mainprocess: forProcess.mainprocess,
          order: forProcess.order,
          workcenter: forProcess.workcenter,
          task: forProcess.task,
          speed: forProcess.speed,
          setup_time: forProcess.setup_time,
          hourly_rate: forProcess.hourly_rate,
          parameters: forProcess.parameters,
        })
        let sizProcess = this.optionUpsertService.sizProcess();
        if (sizProcess) {
          const sizingGroup = this.optionForm.get('sizing') as FormGroup;
          sizingGroup?.setValue({
            mainprocess: sizProcess.mainprocess,
            order: sizProcess.order,
            workcenter: sizProcess.workcenter,
            task: sizProcess.task,
            speed: sizProcess.speed,
            setup_time: sizProcess.setup_time,
            hourly_rate: sizProcess.hourly_rate,
            parameters: sizProcess.parameters,
          })
        }
      }
    }, {allowSignalWrites: true});

    effect(() => {
      if (this.optionUpsertService.allowEditing()) {
        this.items.filter(e => e.id != 'spaceSetup').forEach(item => {
          item.disabled = this.optionUpsertService.projectOptionUuid() == undefined
        });
      } else if (this.optionUpsertService.projectOption() && this.optionUpsertService.project()) {
        this.items.filter(e => e.id != 'otherProcesses').forEach(item => {
          item.disabled = true
        });
        this.active = this.items[5];
      }
    });
  }

  ngOnInit() {
    let projectUuid = this.route.parent?.snapshot.params['id'];
    let projectOptionUuid = this.route.snapshot.params['optionId'];
    if (projectUuid) {
      this.optionUpsertService.initializeUpsertService(projectUuid, projectOptionUuid);
    }
    this.items = [
      {
        label: 'Space setup',
        id: 'spaceSetup',
      },
      {
        label: 'Reference File',
        id: 'referenceFile',
        disabled: !projectOptionUuid,
      },
      {
        label: 'Forming',
        id: 'forming',
        disabled: !projectOptionUuid,
      },
      {
        label: 'Sizing',
        id: 'sizing',
        disabled: !projectOptionUuid,
      },
      {
        label: 'HTT',
        id: 'htt',
        disabled: !projectOptionUuid,
      },
      {
        label: 'Other Processes',
        id: 'otherProcesses',
        disabled: !projectOptionUuid,
      }
    ];
    this.active = this.items[0];

    if (projectOptionUuid && this.optionUpsertService.allowEditing()) {
      this.route.queryParams.subscribe(params => {
        const currentStepId = params['step'];
        let itemIndex = this.items.findIndex(e => e.id === currentStepId);
        if (itemIndex >= 0) {
          this.active = this.items[itemIndex];
        }

      });
    }
  }

  onActiveItemChange(event: MenuItem) {
    this.active = event;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {step: event.id},
        queryParamsHandling: 'merge'
      }
    );
  }

  nextStep() {
    let indexOfActive = this.items.indexOf(this.active);
    switch (indexOfActive) {
      case 0:
        const spaceSetupGroup = this.optionForm.get('spaceSetup') as FormGroup;
        spaceSetupGroup.markAllAsTouched();
        if (spaceSetupGroup.invalid) {
          return;
        }
        if (!this.optionUpsertService.projectOptionUuid()) {
          this.optionUpsertService.addProjectOption(spaceSetupGroup.value)
        } else if (spaceSetupGroup.get('name')?.dirty) {
          this.optionUpsertService.updateProjectOption(spaceSetupGroup.get('name')?.value, undefined);
        }
        break;
      case 2:
        const formingGroup = this.optionForm.get('forming') as FormGroup;
        formingGroup.markAllAsTouched();
        if (formingGroup.invalid) {
          return;
        }
        if (!this.optionUpsertService.forProcess()) {
          this.optionUpsertService.addProcess(formingGroup.value);
        } else if (formingGroup.dirty) {
          this.optionUpsertService.updateFORProcess(formingGroup.value);
        }
        break;
      case 3:
        const sizingGroup = this.optionForm.get('sizing') as FormGroup;
        if (this.optionUpsertService.skipSizing.dirty) {
          this.optionUpsertService.updateProjectOption(undefined, this.optionUpsertService.skipSizing.value ?? false);
        }
        if (!(this.optionUpsertService.skipSizing.value ?? false)) {
          sizingGroup.markAllAsTouched();
          if (sizingGroup.invalid) {
            return;
          }
          if (!this.optionUpsertService.sizProcess()) {
            this.optionUpsertService.addProcess(sizingGroup.value);
          } else if (sizingGroup.dirty) {
            this.optionUpsertService.updateSIZProcess(sizingGroup.value);
          }
        }
        break;
      case 4:
        let allValid = this.httTab()?.validateAllForms();
        if (!allValid) {
          return;
        }
        break;
    }
    this.items[indexOfActive + 1].disabled = false;
    this.active = this.items[indexOfActive + 1];
  }

  previousStep() {
    let indexOfActive = this.items.indexOf(this.active);
    this.active = this.items[indexOfActive - 1];
  }
}
