import {version} from './version';

export const environment: {
  production: boolean;
  version: string;
  apiEndpoint: string;
} = {
  production: false,
  version,
  apiEndpoint: 'https://api.smart-costing-dev.fortysix.io',
};
