<div class="adjust-parameter-dialog flex flex-column gap-3">
  <p class="title">Adjust Parameters</p>
  <div class="table-div">
    <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="parameters">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-color" style="width: 250px">Key</th>
          <th class="text-color" style="width: 100px">OG Value</th>
          <th class="text-color">Adjusted Value</th>
        </tr>
      </ng-template>
      <ng-template let-parameter pTemplate="body">
        <tr>
          <td class="text-color">{{ parameter.name }}</td>
          <td class="text-color">{{ parameter.value }}</td>
          <td class="text-color">{{ parameter.adjusted_value }}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <div class="flex justify-content-end gap-2">
    <p-button (onClick)="cancel()" label="Cancel" severity="secondary"/>
    <p-button (onClick)="save()" label="Save"/>
  </div>
</div>
