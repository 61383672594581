<div [ngClass]="{won: projectOption().status == 'won'}" class="project-option-card flex flex-column gap-4">
  <div class="card-header">
    @if (projectOption().status == 'won') {
      <i class="pi pi-trophy trophy-icon"></i>
    }
    <p class="card-title text-color font-bold">{{ projectOption().name }}</p>
    <p-tag [value]="projectOption().site" severity="secondary"/>
    <div class="flex-grow-1"></div>
    <p class="last-edited">Last edit: {{ projectOption().last_edited | date: 'dd.MM.YYYY' }}</p>
    <a [queryParams]="{step: projectOption().status == 'open' ? 'spaceSetup' : 'otherProcesses'}"
       [routerLink]="projectOption().project_option_uuid ? [projectOption().project_option_uuid] : null">
      <i class="pi pi-chevron-right"></i>
    </a>
  </div>
  <div class="content flex flex-grow-1 align-items-center">
    <div class="flex-grow-1">
      <div class="grid">
        <p class="sub-title col-4">Processes</p>
        <p class="sub-title col-8">Cost per Piece/100 pc</p>
      </div>
      <div class="grid">
        <p class="card-data col-4">{{ projectOption().processes?.length }}</p>
        @if (projectOption().cost_per_piece) {
          <p class="card-data col-8">{{ projectOption().cost_per_piece }} €</p>
        }
      </div>
    </div>

    @if (projectOption().image) {
      <div (mouseenter)="overlayPanel.show($event)" (mouseleave)="overlayPanel.hide()" class="circle">
        <img alt="icon"
             class="icon" height="20"
             ngSrc="../../../../../assets/imgs/deployed_code.svg" width="20"/>
        <p-overlayPanel #overlayPanel>
          <img [ngSrc]="projectOption().image!" alt="object image" class="option-image"
               height="300" width="300"/>

        </p-overlayPanel>
      </div>
    }
  </div>
  <div class="card-footer flex gap-3 w-full justify-content-end align-items-center">
    <p (click)="duplicateOption()" class="text-color font-medium duplicate-text flex-grow-1 cursor-pointer">Duplicate
      Option</p>
    <p-button (onClick)="archiveOption()" class="flex-grow-1 archive-button" severity="secondary">Archive</p-button>
  </div>
</div>
