import {Component, inject, OnInit, signal} from '@angular/core';
import {Button} from "primeng/button";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ProjectOption} from "../../../api/auto-gen";
import {CurrencyPipe} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {RadioButtonModule} from "primeng/radiobutton";
import {FormsModule} from "@angular/forms";
import {TagModule} from "primeng/tag";

@Component({
  selector: 'app-winning-option-dialog',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    DropdownModule,
    PrimeTemplate,
    TableModule,
    RadioButtonModule,
    FormsModule,
    TagModule
  ],
  templateUrl: './winning-option-dialog.component.html',
  styleUrl: './winning-option-dialog.component.scss'
})
export class WinningOptionDialogComponent implements OnInit {
  projectOptions = signal<ProjectOption[]>([]);
  selectedOption: ProjectOption | undefined;
  protected ref = inject(DynamicDialogRef);
  private dynamicDialogConfig = inject(DynamicDialogConfig);

  ngOnInit() {
    if (this.dynamicDialogConfig.data instanceof Array) {
      this.projectOptions.set(this.dynamicDialogConfig.data);
    }
  }

  setProjectAsWon() {
    this.ref.close(this.selectedOption)
  }
}
