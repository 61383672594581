<div class="admin-screen">
  <div class="header flex gap-3">
    <p class="screen-title text-color flex-grow-1">Settings</p>
    <div class="flex flex-column align-items-start gap-3 site-dropdown">
      <label class="label text-color" for="site">Site</label>
      <p-dropdown (onChange)="adminPageFilterService.selectedSite.set($event.value)"
                  [ngModel]="adminPageFilterService.selectedSite()"
                  [options]="sites()"
                  class="w-full"
                  id="site"
                  optionLabel="name"
                  optionValue="name"/>
    </div>
  </div>

  <app-process-component
    class="process-component"/>
  @if (adminPageFilterService.selectedSite()) {
    @defer (on viewport) {
      <app-constants-component/>
    } @placeholder {
      <div class="placeholder"></div>
    }
    @defer (on viewport) {
      <app-formula-component/>
    } @placeholder {
      <div class="placeholder"></div>
    }
    @defer (on viewport) {
      <app-rule-component/>
    } @placeholder {
      <div class="placeholder"></div>
    }
  }

</div>
