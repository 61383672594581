<div class="htt-card">
  <div class="htt-div flex flex-column gap-3">
    <div class="header flex align-items-center gap-3">
      <p class="title flex-grow-1">Heattreatment</p>
      <p-button (onClick)="newHttProcesses().push(uuidv4())">Add HTT Process</p-button>
    </div>
    <div class="htt-table-div">

      @if (optionUpsertService.projectOption()) {
        <app-htt-header-row/>
        @for (process of httProcesses(); track process) {
          <app-htt-row [site]="optionUpsertService.projectOption()!.site" [mainProcess]="mainProcess"
                       [httProcess]="process" [allValid]="allValid()" [processUuid]="process.process_uuid"
                       (onDelete)="onDelete($event)"/>
        }
        @for (newProcess of newHttProcesses(); track newProcess) {
          <app-htt-row [site]="optionUpsertService.projectOption()!.site" [mainProcess]="mainProcess"
                       [processUuid]="newProcess" [allValid]="allValid()" (onDelete)="onDelete($event)"/>
        }

      }
    </div>
  </div>
</div>
