import {Component, inject, OnInit, signal} from '@angular/core';
import {FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DividerModule} from "primeng/divider";
import {InputNumberModule} from "primeng/inputnumber";
import {
  GeneralService,
  ResponseModelTaskList,
  ResponseModelWorkcenterList,
  Task,
  Workcenter
} from "../../../api/auto-gen";
import {DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {ToastService} from "../../../shared/services/toast-service.service";
import {OptionUpsertService} from "../../option-upsert-service.service";
import {CheckboxModule} from "primeng/checkbox";
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-sizing-upsert-component',
  standalone: true,
  imports: [
    DividerModule,
    InputNumberModule,
    ReactiveFormsModule,
    DropdownModule,
    CheckboxModule,
    FormsModule,
    CurrencyPipe
  ],
  templateUrl: './sizing-upsert-component.component.html',
  styleUrl: './sizing-upsert-component.component.scss',
  providers: [GeneralService, ToastService],
})
export class SizingUpsertComponentComponent implements OnInit {
  form: FormGroup | undefined;
  workcenters = signal<Workcenter[]>([]);
  tasks = signal<Task[]>([]);
  protected optionUpsertService = inject(OptionUpsertService)
  private rootFormGroup = inject(FormGroupDirective);
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService);

  ngOnInit() {
    this.optionUpsertService.fetchSIZProcess();
    this.form = this.rootFormGroup.form;
    this.fetchWorkCenters();
    if (this.optionUpsertService.projectOption()?.skip_sizing ?? false) {
      this.form.get('speed')?.disable();
      this.form.get('setup_time')?.disable();
      this.form.get('workcenter')?.disable();
      this.form.get('task')?.disable();
    }
  }

  fetchWorkCenters() {
    if (this.optionUpsertService.projectOption()?.site) {
      this.generalService.getWorkcentersWorkcentersGet({
        site: this.optionUpsertService.projectOption()!.site!,
        mainProcess: 'SIZ'
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelWorkcenterList) => {
              this.workcenters.set(response.data?.workcenter ?? []);
              this.fetchTask();
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchTask(): void {
    if (this.form?.get('workcenter')?.value && this.optionUpsertService.projectOption()) {
      this.generalService.getTasksTasksGet({
        site: this.optionUpsertService.projectOption()!.site,
        mainProcess: 'SIZ',
        workcenter: this.form!.get('workcenter')!.value
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelTaskList) => {
              this.tasks.set(response.data?.tasks ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  workCenterChanged(event: DropdownChangeEvent) {
    const selectedWorkcenter = this.workcenters().find(wc => wc.workcenter_uuid === event.value);
    this.form?.setValue({
      mainprocess: this.form?.value.mainprocess,
      order: this.form?.value.order,
      workcenter: this.form?.value.workcenter,
      task: null,
      speed: this.form?.value.speed,
      setup_time: this.form?.value.setup_time,
      hourly_rate: selectedWorkcenter?.hourlyrate,
      parameters: this.form?.value.parameters,
    });

    this.fetchTask();
  }

  taskChanged(event: DropdownChangeEvent) {
    this.form?.setValue({
      mainprocess: this.form?.value.mainprocess,
      order: this.form?.value.order,
      workcenter: this.form?.value.workcenter,
      task: this.form?.value.task,
      speed: this.form?.value.speed,
      setup_time: event.value.setuptime,
      hourly_rate: this.form?.value.hourly_rate,
      parameters: this.form?.value.parameters,
    });
  }
}
