import {Component, inject, input, OnInit, output, signal} from '@angular/core';
import {
  CostingProcessService,
  GeneralService,
  ProcessOutput,
  ResponseModelFormulaResult,
  ResponseModelProcess,
  ResponseModelTaskList,
  ResponseModelWorkcenterList,
  Task,
  Workcenter
} from "../../../api/auto-gen";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastService} from "../../../shared/services/toast-service.service";
import {Button} from "primeng/button";
import {DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {PaginatorModule} from "primeng/paginator";
import {CurrencyPipe, NgStyle} from "@angular/common";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {AdjustParameterDialogComponent} from "../../adjust-parameter-dialog/adjust-parameter-dialog.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {setupTimeWidth, speedWidth, taskWidth, workCenterWidth} from "../column-widths";
import {OptionUpsertService} from "../../option-upsert-service.service";


@Component({
  selector: 'app-htt-row',
  standalone: true,
  imports: [
    Button,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    PaginatorModule,
    ReactiveFormsModule,
    NgStyle,
    CurrencyPipe
  ],
  templateUrl: './htt-row.component.html',
  styleUrl: './htt-row.component.scss',
  providers: [GeneralService, ToastService, DialogService, DynamicDialogRef, CostingProcessService],
})
export class HttRowComponent implements OnInit {
  httProcess = input<ProcessOutput | undefined>(undefined);
  processUuid = input.required<string>();
  mainProcess = input.required<string>();
  site = input.required<string>();
  allValid = input.required<boolean>();
  onDelete = output<string>();
  form: FormGroup | undefined;
  workcenters = signal<Workcenter[]>([]);
  tasks = signal<Task[]>([]);
  protected readonly workCenterWidth = workCenterWidth;
  protected readonly taskWidth = taskWidth;
  protected readonly speedWidth = speedWidth;
  protected readonly setupTimeWidth = setupTimeWidth;
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService);
  private dialogService = inject(DialogService);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private costingProcessService = inject(CostingProcessService);
  private optionUpsertService = inject(OptionUpsertService);

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      mainprocess: [this.httProcess()?.mainprocess ?? this.mainProcess(), Validators.required],
      order: [this.httProcess()?.order ?? 0, Validators.required],
      workcenter: [this.httProcess()?.workcenter, Validators.required],
      task: [this.httProcess()?.task, Validators.required],
      speed: [this.httProcess()?.speed ?? 10, Validators.required],
      setup_time: [this.httProcess()?.setup_time, Validators.required],
      hourly_rate: [this.httProcess()?.hourly_rate, Validators.required],
      cost_per_piece: [this.httProcess()?.cost_per_piece ?? 10.0, Validators.required],
      parameters: [this.httProcess()?.parameters ?? []],
    });
    this.fetchWorkcenters();
    this.fetchTask();

  }

  onSave(): void {
    if (this.form?.valid && this.optionUpsertService.projectOptionUuid() && this.allValid()) {
      if (this.httProcess()) {
        //update
        this.costingProcessService.updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut({
            projectOptionUuid: this.optionUpsertService.projectOptionUuid()!,
            processUuid: this.processUuid(),
            processInput: {process_uuid: this.processUuid(), ...this.form.value},
          },
        )
          .pipe()
          .subscribe({
              next: (_: ResponseModelProcess) => {
                this.toastService.pushSuccessToast('Process ' + this.processUuid() + ' saved successfully!');
              },
              error: (error: any) => {
                this.toastService.pushErrorToast(error);
              },
            },
          );
      } else {
        //add
        this.costingProcessService.createProcessCostingProjectOptionsProjectOptionUuidProcessPost({
            projectOptionUuid: this.optionUpsertService.projectOptionUuid()!,
            processCreate: {...this.form.value},
          },
        )
          .pipe()
          .subscribe({
              next: (response: ResponseModelProcess) => {
                if (response.data) {
                  this.toastService.pushSuccessToast('Process ' + response.data!.process_uuid + ' added successfully!');
                }

              },
              error: (error: any) => {
                this.toastService.pushErrorToast(error);
              },
            },
          );
      }
    }
  }

  fetchWorkcenters(): void {
    this.generalService.getWorkcentersWorkcentersGet({
      site: this.site(),
      mainProcess: this.mainProcess()
    })
      .pipe()
      .subscribe({
          next: (response: ResponseModelWorkcenterList) => {
            this.workcenters.set(response.data?.workcenter ?? []);
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  fetchTask(): void {
    if (this.form?.get('workcenter')?.value) {
      this.generalService.getTasksTasksGet({
        site: this.site(),
        mainProcess: this.mainProcess(),
        workcenter: this.form!.get('workcenter')!.value
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelTaskList) => {
              this.tasks.set(response.data?.tasks ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  workCenterChanged(event: DropdownChangeEvent) {
    const selectedWorkcenter = this.workcenters().find(wc => wc.workcenter_uuid === event.value);
    this.form?.setValue({
      mainprocess: this.form?.value.mainprocess,
      order: this.form?.value.order,
      workcenter: this.form?.value.workcenter,
      task: null,
      speed: this.form?.value.speed,
      setup_time: this.form?.value.setup_time,
      hourly_rate: selectedWorkcenter?.hourlyrate,
      cost_per_piece: this.form?.value.cost_per_piece,
      parameters: this.form?.value.parameters,
    });

    this.fetchTask();
  }

  taskChanged(event: DropdownChangeEvent) {
    this.form?.setValue({
      mainprocess: this.form?.value.mainprocess,
      order: this.form?.value.order,
      workcenter: this.form?.value.workcenter,
      task: this.form?.value.task,
      speed: this.form?.value.speed,
      setup_time: event.value.setuptime,
      hourly_rate: this.form?.value.hourly_rate,
      cost_per_piece: this.form?.value.cost_per_piece,
      parameters: this.form?.value.parameters,
    });
    // this.evaluateFormula();
  }

  handleDeletion() {

    this.dynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmMessage: "Are you sure you want to delete this process?"
      },
    },);

    this.dynamicDialogRef.onClose.subscribe((deleteProcess: boolean) => {
      if (deleteProcess) {
        this.onDelete.emit(this.processUuid());
      }
    });
  }

  adjustParameter() {
    console.log(`Adjust parameter for ${this.httProcess()}`);
    this.dynamicDialogRef = this.dialogService.open(AdjustParameterDialogComponent, {},);

    this.dynamicDialogRef.onClose.subscribe((parametersUpdated: boolean) => {
      if (parametersUpdated) {
        console.log(`Parameters updated`);
        console.log(this.httProcess());
      }
    });
  }

  evaluateFormula() {
    if (this.form?.value.task && this.optionUpsertService.projectOptionUuid()) {
      this.costingProcessService.evaluateFormulaCostingEvaluateFormulaPost({
        formulaUuid: 'formula UUid', //TODO: fix after clear from where to get formula_uuid
        optionUuid: this.optionUpsertService.projectOptionUuid()!,
        bodyEvaluateFormulaCostingEvaluateFormulaPost: {
          task: this.form?.value.task,
          workcenter: this.form?.value.workcenter,
          adjusted_parameters: this.form?.value.adjusted_parameters,
        },
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelFormulaResult) => {
              this.form?.setValue({
                mainprocess: this.form?.value.mainprocess,
                order: this.form?.value.order,
                workcenter: this.form?.value.workcenter,
                task: this.form?.value.task,
                speed: response.data?.speed,
                setup_time: this.form?.value.setuptime,
                hourly_rate: this.form?.value.hourly_rate,
                cost_per_piece: response.data?.cost_per_piece,
                parameters: this.form?.value.parameters,
              });
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }
}
