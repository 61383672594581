import {Component} from '@angular/core';
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-project-overview-page-component',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './project-overview-page.component.html',
  styleUrl: './project-overview-page.component.scss',
})
export class ProjectOverviewPageComponent {
}
