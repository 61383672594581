import {Component, inject, OnInit, signal, viewChildren} from '@angular/core';
import {Button} from "primeng/button";
import {CurrencyPipe} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {ProgressBarModule} from "primeng/progressbar";
import {TableModule} from "primeng/table";
import {v4 as uuidv4} from 'uuid';
import {
  CostingProcessService,
  ProcessOutput,
  ResponseModelProcess,
  ResponseModelProcessList,
  Task,
  Workcenter
} from "../../api/auto-gen";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../shared/services/toast-service.service";
import {OptionUpsertService} from "../option-upsert-service.service";
import {HttRowComponent} from "./htt-row/htt-row.component";
import {HttHeaderRowComponent} from "./htt-header-row/htt-header-row.component";

@Component({
  selector: 'app-htt-component',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    PrimeTemplate,
    ProgressBarModule,
    TableModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    HttRowComponent,
    HttHeaderRowComponent
  ],
  templateUrl: './htt-component.component.html',
  styleUrl: './htt-component.component.scss',
  providers: [DialogService, DynamicDialogRef, CostingProcessService, ToastService],
})
export class HttComponentComponent implements OnInit {
  httProcesses = signal<ProcessOutput[]>([]);
  newHttProcesses = signal<string[]>([]);
  allValid = signal<boolean>(true);

  httRows = viewChildren(HttRowComponent);
  mainProcess: string = 'HTT';
  workcenters: Workcenter[] = [
    {
      workcenter_uuid: 'a5783ab5-adae-4529-984a-abd50bc8fba4',
      name: 'Sinterhärten Jansky 60cm',
      hourlyrate: 12
    },
    {
      workcenter_uuid: 'e4365335-6099-40a2-977f-7a7c3c6932a9',
      name: "DK-3",
      hourlyrate: 12
    },
  ];
  tasks: Task[] = [
    {
      task_uuid: 'taskUUid1',
      name: 'Task1',
      parameters: [],
      setuptime: 12,
    },
    {
      task_uuid: 'taskUUid2',
      name: 'Task2',
      parameters: [],
      setuptime: 12,
    },
    {
      task_uuid: 'taskUUid3',
      name: 'Task3',
      parameters: [],
      setuptime: 12,
    }
  ]
  protected optionUpsertService = inject(OptionUpsertService);
  protected readonly uuidv4 = uuidv4;
  private costingProcessService = inject(CostingProcessService);
  private toastService = inject(ToastService);

  ngOnInit() {
    this.fetchHTTProcesses();

  }

  validateAllForms(): boolean {
    this.httRows().forEach(child => {
      child.form?.markAllAsTouched();
      if (!child.form?.valid) {
        this.allValid.set(false);
      }
    });
    if (this.allValid()) {
      this.httRows().forEach(child => {
        child.onSave();
      });
    }

    return this.allValid();
  }

  fetchHTTProcesses() {
    if (this.optionUpsertService.projectOptionUuid()) {
      this.costingProcessService.getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(
        {projectOptionUuid: this.optionUpsertService.projectOptionUuid()!, mainprocess: this.mainProcess},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessList) => {
              this.httProcesses.set(response.data?.processes ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  onDelete(uuid: string) {
    if (this.newHttProcesses().includes(uuid)) {
      this.newHttProcesses.set(this.newHttProcesses().filter(e => e != uuid));
    } else if (this.httProcesses().some(e => e.process_uuid == uuid) && this.optionUpsertService.projectOptionUuid()) {
      this.costingProcessService.deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete({
        processUuid: uuid,
        projectOptionUuid: this.optionUpsertService.projectOptionUuid()!,
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcess) => {
              if (response) {
                this.toastService.pushSuccessToast('Process deleted successfully!');
                this.fetchHTTProcesses();
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }
}
