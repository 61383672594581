<div class="other-processes-card">
  <div class="other-processes-div flex flex-column gap-3">
    <div class="header flex align-items-center gap-3">
      <p class="title flex-grow-1">Other Processes</p>
      <p-button>Add Process</p-button>
    </div>
    <div class="other-processes-table-div">
      <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="processes">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-color" style="width: 50px"></th>
            <th class="text-color" style="width: 100px">Position</th>
            <th class="text-color" style="width: 150px">Process</th>
            <th class="text-color" style="width: 250px">Workcenter</th>
            <th class="text-color" style="width: 100px">Speed</th>
            <th class="text-color" style="width: 150px">Setup time</th>
            <th class="text-color">Hourly rate</th>
          </tr>
        </ng-template>
        <ng-template let-item pTemplate="body">
          <tr>
            <td class="text-color">
              @if (!notEditableProcesses.includes(item.mainprocess)) {
                <i class="pi pi-bars"></i>
              }
            </td>
            <td class="text-color">{{ item.order }}</td>
            <td class="text-color">
              @if (notEditableProcesses.includes(item.mainprocess)) {
                {{ item.mainprocess }}
              } @else {
                <p-dropdown [(ngModel)]="item.mainprocess" [options]="editableProcesses" appendTo="body"
                            class="flex-grow-1 dropdown"
                            placeholder="Choose"/>
              }
            </td>
            <td class="text-color">
              @if (notEditableProcesses.includes(item.mainprocess)) {
                {{ item.workcenter }}
              } @else {
                <p-dropdown [(ngModel)]="item.workcenter" [options]="workcenters" appendTo="body"
                            class="flex-grow-1 dropdown"
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Choose"/>
              }
            </td>
            <td class="text-color">{{ item.speed }}</td>
            <td class="text-color">{{ item.setup_time }}h</td>
            <td class="text-color">
              <div class="formula-column flex align-items-center gap-3">
                <p class="flex-grow-1">{{ item.hourly_rate | currency :'EUR' }}</p>
                @if (!notEditableProcesses.includes(item.mainprocess)) {
                  <p-button (onClick)="adjustParameter(item)" class="adjust-button" severity="secondary">Adjust
                    Parameter
                  </p-button>
                  <i (click)="handleDeletion(item)" class="pi pi-trash cursor-pointer"></i>
                }

              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="summary-div flex justify-content-end">
      <div>
        <div class="grid">
          <p class="sub-title col-4">Processes</p>
          <p class="sub-title col-8">Cost per Piece/100 pc</p>
        </div>
        <div class="grid">
          <p class="card-data col-4">{{ processes.length }}</p>
          <!---TODO: change to actual data--->
          <p class="card-data col-8">192,00 €</p>
        </div>
      </div>
    </div>
  </div>
</div>
