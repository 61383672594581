<div class="winning-option-div">
  <p class="title">Select winning project option</p>
  <div class="flex flex-column">
    @for (option of projectOptions(); track option) {
      <div class="field-checkbox">
        <p-radioButton
          [(ngModel)]="selectedOption"
          [inputId]="option.project_option_uuid"
          [value]="option"
          name="category"/>
        <label [for]="option.project_option_uuid" class="ml-2 w-full">
          <div class="flex align-items-center gap-3">
            <p class="flex-grow-1">{{ option.name }}</p>
            <p-tag [value]="option.site" severity="secondary"/>
          </div>
        </label>
      </div>
    }


  </div>
  <div class="flex justify-content-end gap-2">
    <p-button (onClick)="ref.close()" label="Cancel" severity="secondary"/>
    <p-button (onClick)="setProjectAsWon()" label="Close project as won" type="submit"/>
  </div>
</div>
