import {AfterViewInit, Component, ElementRef, ViewChild,} from '@angular/core';
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader.js";

@Component({
  selector: 'app-stl-model-viewer',
  standalone: true,
  imports: [],
  templateUrl: './stl-model-viewer.component.html',
  styleUrl: './stl-model-viewer.component.scss'
})
export class StlModelViewerComponent implements AfterViewInit {
  @ViewChild('threeCanvas', {static: true}) threeCanvas!: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {
    this.initThreeJs();
  }

  initThreeJs(): void {
    const canvas = this.threeCanvas.nativeElement;
    const parentDiv = canvas.parentElement as HTMLElement;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, parentDiv.clientWidth / parentDiv.clientHeight, 0.1, 1000);
    camera.position.set(0, 0, 50);
    camera.lookAt(scene.position);

    const renderer = new THREE.WebGLRenderer({canvas: canvas});
    renderer.setSize(parentDiv.clientWidth, parentDiv.clientHeight);

    const light = new THREE.AmbientLight(0xffffff, 1);
    scene.add(light);

    const loader = new STLLoader();
    //TODO: change with actual url
    loader.load('assets/stl-files/Unnamed.stl', (geometry) => {
      const material = new THREE.MeshNormalMaterial();
      const mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);
      renderer.render(scene, camera);
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    window.addEventListener('resize', () => {
      renderer.setSize(parentDiv.clientWidth, parentDiv.clientHeight);
      camera.aspect = parentDiv.clientWidth / parentDiv.clientHeight;
      camera.updateProjectionMatrix();
    });
  }
}
