import {Component, inject, OnInit, signal} from '@angular/core';
import {Button} from "primeng/button";
import {
  ProjectOptionListComponentComponent
} from "./project-option-list-component/project-option-list-component.component";
import {
  ProjectOptionStateFilterButtonsComponentComponent
} from "./project-option-state-filter-buttons-component/project-option-state-filter-buttons-component.component";
import {
  Project,
  ProjectOption,
  ProjectOverviewService,
  ResponseModel,
  ResponseModelProject,
  ResponseModelProjectOptionList
} from "../../api/auto-gen";
import {Location} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {WinningOptionDialogComponent} from "./winning-option-dialog/winning-option-dialog.component";
import {ToastService} from "../../shared/services/toast-service.service";

@Component({
  selector: 'app-options-page',
  standalone: true,
  imports: [
    Button,
    ProjectOptionListComponentComponent,
    ProjectOptionStateFilterButtonsComponentComponent,
    RouterLink
  ],
  templateUrl: './options-page.component.html',
  styleUrl: './options-page.component.scss',
  providers: [Location, ProjectOverviewService, DialogService, DynamicDialogRef, ToastService]
})
export class OptionsPageComponent implements OnInit {
  project = signal<Project | undefined>(undefined);
  projectOptions = signal<ProjectOption[]>([]);
  stateFilter = signal<'open' | 'won' | 'archived' | null | undefined>(undefined);
  projectUuid: string | undefined;
  route = inject(ActivatedRoute);
  protected location = inject(Location);
  private projectOverviewService = inject(ProjectOverviewService);
  private dialogService = inject(DialogService);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private toastService = inject(ToastService);

  ngOnInit() {
    this.projectUuid = this.route.snapshot.params['id'];
    this.fetchProject();
    this.fetchProjectOptions();
  }

  fetchProject() {
    if (this.projectUuid) {
      this.projectOverviewService.getProjectProjectProjectsProjectUuidGet({
        projectUuid: this.projectUuid,
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelProject) => {
              if (response.data) {
                this.project.set(response.data!);
              }

            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }


  fetchProjectOptions() {
    if (this.projectUuid) {
      this.projectOverviewService.getProjectOptionsProjectProjectsProjectUuidProjectOptionsGet({
        projectUuid: this.projectUuid,
        projectOptionStatus: this.stateFilter()
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOptionList) => {
              let options = response.data?.project_options ?? [];
              if (this.stateFilter() == undefined) {
                options = options.filter(e => e.status != 'archived')
              }
              this.projectOptions.set(options);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  stateFilterChanged(state: 'open' | 'won' | 'archived' | null | undefined) {
    this.stateFilter.set(state);
    this.fetchProjectOptions();
  }

  duplicateOption(projectOptionUuid: string) {
    this.projectOverviewService.duplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePost({
      projectOptionUuid: projectOptionUuid,
    })
      .pipe()
      .subscribe({
          next: (_: ResponseModel) => {
            this.fetchProjectOptions();
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  archiveOption(projectOptionUuid: string) {
    if (this.projectUuid) {
      this.projectOverviewService.updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch({
        projectOptionUuid: projectOptionUuid,
        newStatus: 'archived',
      })
        .pipe()
        .subscribe({
            next: (_: ResponseModel) => {
              this.fetchProjectOptions();
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }

  }

  openWonDialog() {
    this.dynamicDialogRef = this.dialogService.open(WinningOptionDialogComponent, {data: this.projectOptions()});

    this.dynamicDialogRef.onClose.subscribe((winningProjectOption: ProjectOption) => {
      if (winningProjectOption) {
        if (this.projectUuid) {
          this.projectOverviewService.updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch({
            projectOptionUuid: winningProjectOption!.project_option_uuid!,
            newStatus: 'won',
          })
            .pipe()
            .subscribe({
                next: (_: ResponseModel) => {
                  this.fetchProjectOptions();
                },
                error: (error: any) => {
                  this.toastService.pushErrorToast(error);
                },
              },
            );
        }
        this.fetchProjectOptions();
      }
    });
  }
}
