import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AppBarComponent} from "./shared/app-bar/app-bar.component";
import {ToastModule} from "primeng/toast";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AppBarComponent, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'smart-costing';
}
