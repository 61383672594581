@if (form) {
  <form [formGroup]="form" class="forming-upsert-div">
    <div class="forming-upsert">
      <div class="flex flex-column align-items-center mb-3">
        <div class="flex w-full align-items-center gap-1">
          <label class="label text-color" for="speed">Speed</label>
          <p-inputNumber class="flex-grow-1" formControlName="speed" id="speed" locale="de-DE"/>
        </div>
        @if (form.get('speed')?.invalid && form.get('speed')?.touched) {
          <small class="p-error w-full">
            Required
          </small>
        }
      </div>
      <p-divider/>
      <div class="flex flex-column align-items-center mb-3">
        <div class="flex w-full align-items-center gap-1">
          <label class="label text-color" for="setupTime">Setup time(h)</label>
          <p-inputNumber class="flex-grow-1" formControlName="setup_time" id="setupTime" locale="de-DE"/>
        </div>
        @if (form.get('setup_time')?.invalid && form.get('setup_time')?.touched) {
          <small class="p-error w-full">
            Required
          </small>
        }
      </div>
      <p-divider/>
      <div class="flex flex-column align-items-center mb-3">
        <div class="flex w-full align-items-center gap-1">
          <label class="label text-color" for="workcenter">Workcenter</label>
          <p-dropdown [options]="workcenters()" class="flex-grow-1"
                      formControlName="workcenter"
                      optionValue="workcenter_uuid"
                      optionLabel="name"
                      placeholder="Select a different Workcenter"
                      (onChange)="workCenterChanged($event)"
                      id="workcenter"/>
        </div>
        @if (form.get('workcenter')?.invalid && form.get('workcenter')?.touched) {
          <small class="p-error w-full">
            Required
          </small>
        }
      </div>
      <p-divider/>
      <div class="flex w-full align-items-center gap-1">
        <label class="label text-color" for="task">Task</label>
        <p-dropdown [options]="tasks()" class="flex-grow-1"
                    formControlName="task"
                    optionLabel="name"
                    placeholder="Choose"
                    (onChange)="taskChanged($event)"
                    id="task"/>
        @if (form.get('task')?.invalid && form.get('task')?.touched) {
          <small class="p-error">
            Required
          </small>
        }
      </div>
      <p-divider/>
      <div class="flex flex-column align-items-end mb-3">
        <p class="hourly-rate-text">Hourly Rate: <span
          class="hourly-rate-value">@if (form.get('hourly_rate')?.value != null) {
          {{ form.get('hourly_rate')?.value | currency: 'EUR':'symbol':undefined }}
        } @else {
          /
        }</span></p>
      </div>
    </div>
  </form>
}
