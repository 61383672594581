<div class="custom-slider">
  <p class="label">{{ label() }}</p>
  <div class="slider-container">
    <div class="slider-div">
      <div [ngStyle]="{'left.%': (value * 10) - 1.5}" class="tooltip">
        {{ value }}
      </div>
      <p-slider (onChange)="updateValue($event)" [(ngModel)]="value" [max]="10" [min]="0" [step]="0.01"
                class="slider">
      </p-slider>
    </div>
    <div class="slider-ticks">
      @for (tick of ticks; track tick) {
        <div class="slider-tick flex flex-column align-items-center">
          <p class="slider-divider">|</p>
          <span class="text-color">{{ tick }}</span>
        </div>

      }
    </div>
  </div>
</div>


