<div class="infos">
  <p class="text-color">App release</p>
  <p class="text-color">{{ appVersion }}</p>
  <p class="text-color">Angular release</p>
  <p class="text-color">{{ angularVersion }}</p>
  <p class="text-color">PrimeNg release</p>
  <p class="text-color">{{ primeNgVersion }}</p>
  <p class="text-color">Environment</p>
  <p class="text-color">{{ environment }}</p>
</div>
