import {Component, inject, OnInit} from '@angular/core';
import {Button} from "primeng/button";
import {CurrencyPipe} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {ProcessInput, Workcenter} from "../../api/auto-gen";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {AdjustParameterDialogComponent} from "../adjust-parameter-dialog/adjust-parameter-dialog.component";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-other-processes-component',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    DropdownModule,
    PrimeTemplate,
    TableModule,
    FormsModule
  ],
  templateUrl: './other-processes-component.component.html',
  styleUrl: './other-processes-component.component.scss',
  providers: [DialogService, DynamicDialogRef],
})
export class OtherProcessesComponentComponent implements OnInit {

  processes: ProcessInput[] = [
    {
      process_uuid: 'processUuid1',
      mainprocess: 'HTT',
      order: 0,
      workcenter: 'DK-3',
      task: {
        task_uuid: 'taskUUid1',
        name: 'Task1',
        parameters: [],
        setuptime: 12,
      },
      speed: 7,
      setup_time: 8,
      hourly_rate: 50,
      parameters: [],
      cost_per_piece: 0,
    },
    {
      process_uuid: 'processUuid2',
      mainprocess: 'FRG',
      order: 1,
      workcenter: 'Sinterhärten Jansky 60cm',
      task: {
        task_uuid: 'taskUUid1',
        name: 'Task1',
        parameters: [],
        setuptime: 12,
      },
      speed: 7,
      setup_time: 8,
      hourly_rate: 50,
      parameters: [],
      cost_per_piece: 0,
    },
    {
      process_uuid: 'processUuid3',
      mainprocess: 'HTT',
      order: 2,
      workcenter: 'Sinterhärten Jansky 60cm',
      task: {
        task_uuid: 'taskUUid1',
        name: 'Task1',
        parameters: [],
        setuptime: 12,
      },
      speed: 7,
      setup_time: 8,
      hourly_rate: 50,
      parameters: [],
      cost_per_piece: 0,
    },
  ];
  workcenters: Workcenter[] = [
    {
      workcenter_uuid: 'a5783ab5-adae-4529-984a-abd50bc8fba4',
      name: 'Sinterhärten Jansky 60cm',
      hourlyrate: 12
    },
    {
      workcenter_uuid: 'e4365335-6099-40a2-977f-7a7c3c6932a9',
      name: "DK-3",
      hourlyrate: 12
    },
  ];

  notEditableProcesses: string[] = [
    'FOR',
    'SIZ',
    'HTT'
  ];

  mainProcesses: string[] = [
    'FOR',
    'SIZ',
    'HTT',
    'FRG',
    'MEC',
    'SEC',
    'INS',
    'PCK',
  ];

  editableProcesses: string[] = [];
  private dialogService = inject(DialogService);
  private dynamicDialogRef = inject(DynamicDialogRef);

  ngOnInit() {
    //TODO: fetch processes
    this.editableProcesses = this.mainProcesses.filter(x => !this.notEditableProcesses.includes(x));
  }

  handleDeletion(htt: ProcessInput) {

    this.dynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmMessage: "Are you sure you want to delete this process?"
      },
    },);

    this.dynamicDialogRef.onClose.subscribe((deleteProcess: boolean) => {
      if (deleteProcess) {
        //TODO: handle deletion of process
        console.log(`Process get deleted`);
        console.log(htt);
      }
    });
  }

  adjustParameter(htt: ProcessInput) {
    console.log(`Adjust paramter for ${htt}`);
    this.dynamicDialogRef = this.dialogService.open(AdjustParameterDialogComponent, {},);

    this.dynamicDialogRef.onClose.subscribe((parametersUpdated: boolean) => {
      if (parametersUpdated) {
        console.log(`Parameters updated`);
        console.log(htt);
      }
    });
  }
}
