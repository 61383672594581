<div class="process-component">
  <p-button (onClick)="adminPageFilterService.selectedMainProcess.set(undefined)"
            [ngClass]="{'selected-button': adminPageFilterService.selectedMainProcess() === undefined}"
            class="process-button" label="Global"
            pRipple/>
  <div class="vertical-divider"></div>
  @for (mainProcess of mainProcesses(); track mainProcess) {
    <p-button (onClick)="adminPageFilterService.selectedMainProcess.set(mainProcess.name)"
              [ngClass]="{'selected-button': adminPageFilterService.selectedMainProcess() === mainProcess.name}"
              class="process-button"
              [label]="mainProcess.name" pRipple/>
  }
</div>
