<div class="sizing-div">
  <div class="sizing-card flex flex-column gap-3">
    <p class="title text-color font-bold">Sizing</p>
    <p class="subtitle text-color">Most similar Parts in Sizing based on Height, Pressarea, Lower and upper punches</p>
    <div class="content  grid align-items-start">
      <div class="col-8">
        <div class="sizing-table-card">
          <div class="sizing-table-div">
            <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="optionUpsertService.similarItems()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-color" style="width: 200px">Matching factor</th>
                  <th class="text-color" style="width: 150px">Item</th>
                  <th class="text-color" style="width: 150px">Workcenter</th>
                  <th class="text-color" style="width: 150px">Task</th>
                  <th class="text-color" style="width: 100px">Speed</th>
                  <th class="text-color" style="width: 100px">Setup time</th>
                  <th class="text-color">Hourly rate</th>
                </tr>
              </ng-template>
              <ng-template let-item pTemplate="body">
                <tr>
                  <td class="text-color">
                    <p-progressBar [value]="item.matching_factor"/>
                  </td>
                  <td class="text-color">{{ item.item }}</td>
                  <td class="text-color">{{ item.workcenter_name }}</td>
                  <td class="text-color">{{ item.task_name }}</td>
                  <td class="text-color">{{ item.speed }}</td>
                  <td class="text-color">{{ item.setup_time }}h</td>
                  <td class="text-color">
                    <div class="formula-column flex align-items-center gap-3">
                      <p class="flex-grow-1">{{ item.hourly_rate | currency :'EUR' }}</p>
                      <p-button (onClick)="applySimilarItem(item)" class="apply-button" severity="secondary">Apply
                      </p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

        </div>
      </div>
      @if (form) {
        <ng-container [formGroup]="form">
          <app-sizing-upsert-component class="col-4"/>
        </ng-container>
      }
    </div>
  </div>
</div>
