<p-table [value]="[referenceFileProperties()]">
  <ng-template pTemplate="header">
    <tr>
      <th class="text-color" style="width: 200px">Feature</th>
      <th class="text-color table-value">Value</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body">
    <tr>
      <td class="text-color">Weight</td>
      <td class="text-color table-value">{{ referenceFileProperties().weight }} g</td>
    </tr>
    <tr>
      <td class="text-color">APress</td>
      <td class="text-color table-value">{{ referenceFileProperties().apress }}
        mm2
      </td>
    </tr>
    <tr>
      <td class="text-color">FPress</td>
      <td class="text-color table-value">{{ referenceFileProperties().fpress }}
        kN@if (referenceFileProperties().fpresst) {
          <span>({{ referenceFileProperties().fpresst }} t)</span>
        }</td>
    </tr>
    <tr>
      <td class="text-color">BBox-x</td>
      <td class="text-color table-value">{{ referenceFileProperties().bbox_x }} mm</td>
    </tr>
    <tr>
      <td class="text-color">BBox-y</td>
      <td class="text-color table-value">{{ referenceFileProperties().bbox_y }} mm</td>
    </tr>
    <tr>
      <td class="text-color">BBox-z</td>
      <td class="text-color table-value">{{ referenceFileProperties().bbox_z }} mm</td>
    </tr>
    <tr>
      <td class="text-color">Volume</td>
      <td class="text-color table-value">{{ referenceFileProperties().volume }} mm3</td>
    </tr>
    <tr>
      <td class="text-color">ASurface</td>
      <td class="text-color table-value">{{ referenceFileProperties().asurface }} mm2</td>
    </tr>
    <tr>
      <td class="text-color">#Cores</td>
      <td class="text-color table-value">{{ referenceFileProperties().nr_cores }}</td>
    </tr>
    <tr>
      <td class="text-color">#Triangles(STL)</td>
      <td class="text-color table-value">{{ referenceFileProperties().nr_triangles }}</td>
    </tr>
  </ng-template>
</p-table>
