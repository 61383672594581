import {Component, inject, input, OnInit, signal} from '@angular/core';
import {ChipsModule} from "primeng/chips";
import {FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RadioButtonModule} from "primeng/radiobutton";
import {GeneralService, ResponseModelSiteList} from "../../api/auto-gen";
import {ToastService} from "../../shared/services/toast-service.service";

@Component({
  selector: 'app-space-setup-component',
  standalone: true,
  imports: [
    ChipsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    FormsModule
  ],
  templateUrl: './space-setup-component.component.html',
  styleUrl: './space-setup-component.component.scss',
  providers: [GeneralService, ToastService],
})
export class SpaceSetupComponentComponent implements OnInit {
  formGroupName = input<string>('');
  form: FormGroup | undefined;
  generalService = inject(GeneralService);
  toastService = inject(ToastService);
  sites = signal<string[]>([]);

  filteredSites: string[] = [];

  private rootFormGroup = inject(FormGroupDirective);


  ngOnInit() {
    this.fetchSites();
    this.form = this.rootFormGroup.control.get(this.formGroupName()) as FormGroup;
  }

  onFullTextFilterChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const filterValue = input.value;
    this.filteredSites = this.sites().filter(x => x.toLowerCase().includes(filterValue.toLowerCase()));
  }

  fetchSites() {
    this.generalService.getSitesSitesGet()
      .pipe()
      .subscribe({
          next: (response: ResponseModelSiteList) => {
            if (response.data) {
              this.sites.set(response.data!.sites.map(x => x.name));
              this.filteredSites = [...this.sites()];
            }

          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  onSiteDivClicked(site: string) {
    if (this.form?.get('site')?.enabled ?? false) {
      this.form?.setValue({
        name: this.form?.value.name,
        site: site,
        lot_quantity: this.form?.value.lot_quantity,
        yearly_quantity: this.form?.value.yearly_quantity,
      })
    }

  }
}
